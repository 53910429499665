@font-face {
  font-family: "Hanson Bold";
  src: url("fonts/Hanson-Bold.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}

html,
body,
#root {
  height: 100%;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

header a {
  color: white;
  text-decoration: none;
}

button {
  background-color: #72e2e6;
  font-family: "Hanson Bold";
  padding: 20px 40px;
  border: none;
  border-radius: 0.4rem;
  outline: none;
  transition: 0.2s opacity ease-in-out;
  font-size: 1rem;
}

button:hover {
  opacity: 0.8;
  cursor: pointer;
}
